<template>

  <div>
    <!-- Table Container Card -->
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem "
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="9"
            class="mobile_tab_max_width_flex all_btn_tab"
          >
            <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem"
                placeholder="Search..."
                @input="filterTable"
              />
              

              <b-button
                variant="primary"
                @click="openModel"
                class="mr-1 mobile-margin-bottom"
              >
                <span class="text-nowrap">Import</span>
              </b-button>

              <b-button
                variant="success"
                class="mr-1 mobile-margin-bottom"
                @click="download('pdf')"
              >
                <span class="text-nowrap">PDF</span>
              </b-button>

              <b-button
                variant="secondary"
                @click="download('excel')"
                class="mr-1 mobile-margin-bottom"
              >
                <span class="text-nowrap">Excel</span>
              </b-button>

              <b-button
                variant="info"
                class="mr-1 mobile-margin-bottom"
                @click="updateModel"
              >
                <span class="text-nowrap">Update Type</span>
              </b-button>

              <b-button
                variant="danger"
                class="mr-1 mobile-margin-bottom"
                @click="deleteAll"
              >
                <span class="text-nowrap">Delete</span>
              </b-button>

              <b-button
                variant="warning"
                :to="{ name: 'add-site-locations', params: { site_id: this.$route.params.site_id } }"
                class="mobile-margin-bottom"
              >
                <span class="text-nowrap">Add Location</span>
              </b-button>

            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative flex_1000 mTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        <!-- Column: User -->
        <!-- <template #cell(site_name)="items">
           
            <p class="wordBreak">{{ items.item.site.site_name | capitalize}}</p>
         
        </template> -->

        <template #head(checkbox)="items">
          <b-form-checkbox class="custom-control-warning" v-model="selectAll" @change="selectall"/>
        </template>

        <template #cell(checkbox)="items">
           
            <b-form-checkbox v-model="selected" :value="items.item._id" class="custom-control-warning" />
         
        </template>


        <template #cell(name)="items">
           
            <p class="wordBreak">{{ items.item.name | capitalize}}</p>
         
        </template>

        <!-- Column: Role -->
        <template #cell(type)="items">
           
            <p class="wordBreak">{{ items.item.type.join(', ') }}</p>
         
        </template>

        
        <template #cell(status)="items">
           
            <b-badge
              pill
              :variant="variantColor(items.item.status)"
              class="text-capitalize"
            >
              {{ items.item.status}}
            </b-badge>
         
        </template>

        <template #cell(created_at)="items">
           
            <!-- <span>{{ items.item.created_at | dateTime}}</span> -->
            <span>{{sitedateTime(items.item.created_at,items.item.site.pdf_date_format,items.item.site.pdf_time_format)}}</span>
         
        </template>

        <template #cell(actions)="items">

          <b-button
            size="sm"
            variant="warning"
            class="mr-1"
            :to="{ name: 'location-areas', params: { site_id: items.item.site._id ,location_id: items.item._id } }"
          >
            Area
          </b-button>

          <!-- `<b-button
            size="sm"
            variant="warning"
            class="mr-1"
            v-if="checkPermission('feedback-panel-settings', 'Update')"
            :to="{ name: 'feedback-panel-settings', params: { site_id: items.item.site._id ,location_id: items.item._id } }"
          >
            Panel Settings
          </b-button>` -->

          <!-- <b-link :to ="{ name: 'location-areas', params: { site_id: items.item.site._id ,location_id: items.item._id } }"
          v-b-tooltip.hover.v-warning
          title="View Area"
          variant="outline-warning"
          >
              <feather-icon icon="EyeIcon" class="mr-1 mediumSize"/>
              
            </b-link> -->


            <!-- <b-link :to="{ name: 'edit-site-locations', params: { id: items.item._id } }"
            v-b-tooltip.hover.v-warning
            title="Edit Location"
            variant="outline-warning"
            >
              <feather-icon icon="EditIcon" class="mediumSize"/>
              
            </b-link> -->
<!-- 
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
            class="common_btn_padding "
          > -->

            <!-- <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template> -->

            <!-- <b-dropdown-item :to ="{ name: 'location-areas', params: { site_id: items.item.site ,location_id: items.item._id } }">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View Area</span>
            </b-dropdown-item> -->

<!-- 
            <b-dropdown-item :to="{ name: 'edit-site-locations', params: { id: items.item._id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="statusChange(items.item)">
              <feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
              <span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
              <span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteItem(items.item._id)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item> -->

            
            
          <!-- </b-dropdown>  -->

           <b-link :to="{ name: 'edit-site-locations', params: { id: items.item._id } }"
            v-b-tooltip.hover.v-warning
            title="Edit"
            variant="outline-warning"
          >
              <feather-icon icon="EditIcon" class="mediumSize mr-1"/>
              
          </b-link>

            <b-link v-b-tooltip.hover.v-warning :title= "items.item.status == 'active' ? 'Mark Inactive' : 'Mark Active'" variant="outline-warning" @click="statusChange(items.item)">
                <feather-icon :icon="items.item.status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize mr-1" />	
            </b-link>

           <b-link @click="deleteItem(items.item._id)"
            v-b-tooltip.hover.v-warning
            title="Delete"
            variant="outline-warning"
          >
              <feather-icon icon="Trash2Icon" class="mediumSize mr-1"/>
              
          </b-link>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>


    <b-modal
        id="import"
        ref="import"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Import Location"
        @ok="uploadLoc"
        
        size="lg"
        no-close-on-backdrop
      >

        <b-alert
          v-model="showDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <b-form>
          
          
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <div class="d-flex align-items-center justify-content-end">
                
                <b-button
                  variant="danger"
                  align="right"
                  @click="sampleDownload"
                >
                  <span class="text-nowrap">Download Sample</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
      
      
          <b-row>
            
            
            <b-col md="12">
              <b-form-group
                label="Upload File Template"
                
                class="required"
              >
                <b-form-file
                  id="upload_file"
                  ref="uploadFile"
                  accept=".xls, .xlsx, .csv"
                  @change="onFileChange"
                  v-model="bulkFile"
                />
                <small class="text-danger">supported format: .xls or .xlsx</small>
            </b-form-group>
            </b-col>
          </b-row>
       
          <!-- submit and reset -->
      
        </b-form>
      </b-modal>


      <b-modal
        id="update"
        ref="update"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Update Location Type"
        @ok="updateType"
        size="lg"
        
        no-close-on-backdrop
      >

        <b-alert
          v-model="showDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <b-form>
          
          <b-row>
            <b-col md="12" >
              
              <b-form-group
                label="Type"
                class="required"

              >

                <v-select
                  v-model="update.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :options="types"
                  placeholder="Select"
                  @input="updateTypeCheck()"
                  :close-on-select=false
                            :clear-on-select=false
                />

                  
              </b-form-group>
            </b-col>

          </b-row>



          <b-row>
            
            <b-col md="12" v-if="update.type.indexOf('NFC') >= 0">
              <b-form-group
                label="NFC Tag"
                class="required"
              >
                <b-form-input
                  
                  placeholder="upto 50 characters"
                  v-model="update.nfc_tag"
                
                />
              </b-form-group>
            </b-col>

          </b-row>

          <b-row v-if="update.type.indexOf('Toilet') >= 0">
            
            <b-col md="12">
                <b-form-group
                  label="Toilet Type"
                  class="required mb-0"
                >
                  <div class="demo-inline-spacing">
                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="male"
                      v-model="update.toilet_type"
                    >
                      Male Toilet
                    </b-form-radio>

                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="female"
                      v-model="update.toilet_type"
                    >
                      Female Toilet
                    </b-form-radio>

                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="handicapped"
                      v-model="update.toilet_type"
                    >
                      Handicap Toilet
                    </b-form-radio>

                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="other"
                      v-model="update.toilet_type"
                    >
                      Other
                    </b-form-radio>

                  </div>
                </b-form-group>
            </b-col>

          </b-row>


          
      
        </b-form>
      </b-modal>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip,BForm,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BFormFile,BFormGroup, BFormCheckbox,BBreadcrumb,BFormRadio
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    vSelect,
    BAlert,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    BFormGroup,
    BFormCheckbox,
    BBreadcrumb,
    BFormRadio
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
       tableColumns: [
            //{ key: 'site_name', label: 'Project Site Name', sortable: false , thStyle:  {width: '20%'}},
            { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '1%'}},
            { key: 'name', label: 'Location Name', sortable: true , thStyle:  {width: '28%'}},
            { key: 'type', label: 'Type', sortable: false , thStyle:  {width: '26%'}},
            
            { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '10%'}},
            { key: 'created_at', label: 'Added On', sortable: true , thStyle:  {width: '15%'}},
            { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '20%'}},
          ],
        error_message:null,
        items:[],
        isSortDirDesc: false,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'name',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        form:{
          file:'',
        },

        selectedFile:{
          file:null,
          name:'',
        },
        bulkFile:null,
        showDismissibleAlert:false,
        selectAll:false,
        selected:[],
        update:{
          type:[],
          nfc_tag:'',
          toilet_type:''
        },
        types:['QI','Periodic Task','NFC','Toilet','Other'],
    }
  },
  methods : {
    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     site_id: this.$route.params.site_id,
                   },
                   api: '/api/location-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    statusChange(item){
      if (item.status == 'active') {
          var msg = 'Are you sure want to inactivate this record?';
          var message='Location Inactivated Successfully.';
      } 
      else{
          var msg = 'Are you sure want to activate this record?';
          var message='Location Activated Successfully.';
      }

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: item._id,
                          status:item.status
                        },
                    api:"/api/change-location-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
             
          })
    },

    deleteItem(id){
      var msg = 'Are you sure want to delete this record?';
      var message='Location Deleted Successfully.';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                return this.$store.dispatch(POST_API, {
                  data:{
                        id: [id],
                      },
                  api:"/api/check-active-tasks",
                  })
                  .then(() => {
                      if (this.$store.getters.containsErrors) {
                          this.error_message = this.$store.getters.getErrors;
                          this.errorAlert();

                      } else {
                        
                        if(this.$store.getters.getResults.data > 0){
                          
                          Swal.fire({
                            position : 'center',
                            icon     : 'error',
                            title    : this.$store.getters.getResults.message,
                            timer    : 3000,
                            showConfirmButton : false
                          });

                        }else{

                          return this.$store.dispatch(POST_API, {
                            data:{
                                  id: id,
                                  status:'deleted'
                                },
                            api:"/api/change-location-status",
                            })
                            .then(() => {
                                if (this.$store.getters.containsErrors) {
                                    this.error_message = this.$store.getters.getErrors;
                                    this.showAlert();
                                } else {
                                  
                                  Swal.fire({
                                    position: 'center',
                                    icon: 'success',
                                    title: message,
                                    showConfirmButton: false,
                                    timer: 1500
                                  })
                                  
                                  this.$refs.refUserListTable.refresh();
                                }
                            });
                        }
                      }
                  });
              }
            })
            .catch(err => {
                
            })
    },
    openModel(item){

      this.showDismissibleAlert = false;

      this.form = {
          file:'',
          site:'',
        };

      this.selectedFile = {
          file:null,
          name:'',
        };
      
      this.$refs['import'].show();
    },
    updateModel(){

      this.showDismissibleAlert = false;
      this.update = {
        type:[],
        nfc_tag:'',
        toilet_type:'',
      };
      
      this.$refs['update'].show();
    },
    sampleDownload(){
      var url = process.env.VUE_APP_SERVER_URL + '/samplefiles/Locations.xlsx';
      window.open(url, '_blank');
    },
    onFileChange(event) {
            
      this.selectedFile.file = null;
      this.selectedFile.name = '';
      var input = event.target;
      
      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          this.form.file = input.files[0];
          

          if ((this.form.file.type != 'application/vnd.ms-excel') && this.form.file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
              
              this.selectedFile.file = null;
              this.selectedFile.name = '';
              
              this.form.file="";
              this.bulkFile = null;

              this.showDismissibleAlert=false;
              Swal.fire({
                icon: 'error',
                title: "Please upload .xls or .xlsx files",
              })
          } else if (this.form.file.size > 2097152) {

              this.form.file="";
              this.selectedFile.file = null;
              this.selectedFile.name = '';
              this.showDismissibleAlert=false;
              this.bulkFile = null;

              Swal.fire({
                icon: 'error',
                title: "Maximum 20 MB files are allowed to upload"
              })
          } else {
              reader.onload = (e) => {
                  
                  this.selectedFile.file = e.target.result;
                  this.selectedFile.name = this.form.file.name;

                  this.showDismissibleAlert=false;
              }
          }
      }
    },
    uploadLoc(e){
      e.preventDefault();
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.$route.params.site_id,
             selectedfile:this.selectedFile,
             
           },
           api: '/api/import-locations'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                this.$refs.uploadFile.value=null;
                this.selectedFile.file = null;
                this.selectedFile.name = '';
                this.bulkFile = null;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Location Imported Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    

                    this.filterTable();
                    this.$refs['import'].hide();
                    ;
                });
                
            }
        });
    },
    download(type){

      var keyword = this.searchQuery;
      var sortBy = this.sortBy;
      var sortDirection = this.isSortDirDesc;
      var site = this.$route.params.site_id

      

      var params = "keyword="+keyword+"&sortBy="+sortBy+"&sortDirection="+sortDirection+"&site="+site+"&organization="+ this.$store.getters.currentUser.organization;
      
      var baseurl = "";
      
      if (type == 'pdf') {
        
        baseurl = process.env.VUE_APP_SERVER_URL +"/api/locations-pdf?"+params;
        
      }else{
        
        baseurl = process.env.VUE_APP_SERVER_URL +"/api/locations-excel?"+params;

      }

      window.open(baseurl,'_blank');
      
    },
    selectall(e){

      this.selected = [];
      
      if (e) {
        var selected = [];

        this.items.forEach(function(item){
            selected.push(item._id);
        })

        this.selected = selected;

      }
    },

    updateType(e){
      e.preventDefault();

      if (this.selected.length > 0) {

        return this.$store.dispatch(POST_API, {
            data:{
                  id: this.selected,
                  data:this.update
                },
            api:"/api/update-location-types",
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;
                      this.showDismissibleAlert = true;
                  } else {
                    
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: 'Location types updated Successfully',
                      showConfirmButton: false,
                      timer: 1500
                    })
                    
                    this.selected = [];
                    this.selectAll = false;
                    this.$refs['update'].hide();
                    this.$refs.refUserListTable.refresh();
                  }
              }); 

      }else{
        Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Please select locations.',
            showConfirmButton: false,
            timer: 1500
          })
      }
      
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to: null, // hyperlink
        text: 'Project Sites' // crumb text
      },
      {
        to: {name:'job-sites'},
        text: 'Sites', // crumb text
      },
      {
        to: null, // hyperlink
        text: 'Locations', // crumb text
        active: true
      }];
      return item;
    },
    updateTypeCheck(){
      if(this.update.type.indexOf('Toilet') < 0){
        this.update.toilet_type = '';
      }
    },
    deleteAll(e){
      if (this.selected.length > 0) {

        var msg = 'Are you sure want to delete this record?';
        var message='Location Deleted Successfully.';

        Swal.fire({
              title: 'Please Confirm',
              text: msg,
              icon: 'warning',
              position: 'top-center',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes',
              cancelButtonText: 'No'
            })
          .then(result => {
            if (result.value) {
              
              this.checkActiveTasks(this.selected);

            }
          })


      }else{
        Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Please select locations.',
            showConfirmButton: false,
            timer: 1500
          })
      }
    },
    checkActiveTasks(idArray){
      return this.$store.dispatch(POST_API, {
        data:{
              id: idArray,
            },
        api:"/api/check-active-tasks",
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.errorAlert();

              } else {
                
                if(this.$store.getters.getResults.data > 0){
                  
                  Swal.fire({
                    position : 'center',
                    icon     : 'error',
                    title    : this.$store.getters.getResults.message,
                    timer    : 3000,
                    showConfirmButton : false
                  });

                }else{
                  this.deleteMultipleLoc();
                }
              }
          });
    },
    deleteMultipleLoc(){
      return this.$store.dispatch(POST_API, {
        data:{
              id: this.selected,
            },
        api:"/api/delete-multiple-locations",
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.errorAlert();

              } else {
                
                Swal.fire({
                  position : 'center',
                  icon     : 'success',
                  title    : this.$store.getters.getResults.message,
                  timer    : 1500,
                  showConfirmButton : false
                });
                this.selected = [];
                this.selectall = false;
                this.filterTable();

              }
          });
    }
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
